import React from 'react';
import Form from '@rjsf/material-ui';

import Grid from '@material-ui/core/Grid';

import * as ra from 'react-admin';
import L from 'leaflet';

import GeofenceSelect from './GeofenceSelect';
import FormButtons from '../FormButtons';
import Map from './Map';

const EditGeofenceGroup = props => {
  const { id } = props;

  const dataProvider = ra.useDataProvider();
  const [ geofenceGroup, setGeofenceGroup ] = React.useState();
  const [ geofences, setGeofences ] = React.useState();
  const [ layers, setLayers] = React.useState([]);

  /*
   * Load item on mount
   */

  React.useEffect(() => {
    const loadGeofenceGroup = id => {
      return dataProvider.sendRequest(`/geofence-groups/${id}`)
        .then(res => res.data)
    };

    Promise.all([
      dataProvider.sendRequest(`/geofences?limit=500`).then(res => res.data.geofences),
      id ? loadGeofenceGroup(id) : Promise.resolve(newGeofenceGroup)
    ])
      .then(([ geofences, geofenceGroup ]) => {
        setGeofences(geofences);
        setGeofenceGroup(geofenceGroup);
      })
  }, [ dataProvider, id ]);

  const rebuildLayers = React.useCallback(({ geofenceIds }) => {
    const layers = geofenceIds.reduce((memo, gid) => {
      const geofence = geofences.find(item => item.id === gid);

      if (!geofence) {
        console.error('geofence', gid, 'not found!');
        return memo;
      }

      geofence.geoJson.forEach(item => {
        const layer = L.geoJSON(item, {
          pointToLayer: (feature, latlng) => {
            return new L.Circle(latlng, feature.properties.radius);
          }
        });

        layer.setStyle({ color: geofence.colorHex });
        memo.push(layer);
      });

      return memo;
    }, []);

    setLayers(layers);
  }, [ geofences ]);

  React.useEffect(() => {
    if (geofences && geofenceGroup) {
      rebuildLayers(geofenceGroup);
    }
  }, [ geofences, geofenceGroup, rebuildLayers ]);

  /*
   * JSONSchema Form change
   */

  const handleFormChange = ({ formData }) => {
    setGeofenceGroup(formData);
  }

  /*
   * JSONSchema Form submit
   */

  const handleSubmit = async () => {
    const url = geofenceGroup.id ? `/geofence-groups/${id}` : '/geofence-groups';
    const method = geofenceGroup.id ? 'put' : 'post';

    await dataProvider.sendRequest(url, { 
      method, 
      body: JSON.stringify(geofenceGroup)
    });
    
    props.history.push('/geofence-groups');
  };

  const handleZoomPan = viewSettings => {
    setGeofenceGroup(geofenceGroup => ({
      ...geofenceGroup,
      viewSettings
    }));
  };

  return geofenceGroup && geofences ? (
    <>
      <EditActions {...props}/>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Map
            zoom={geofenceGroup.viewSettings.zoom}
            center={geofenceGroup.viewSettings.center}
            setZoomPan={handleZoomPan}
            layers={layers}
          />
        </Grid>
        <Grid item xs={5}>
          <Form
            formData={geofenceGroup}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            onChange={handleFormChange}
            fields={{ GeofenceSelect }}
            formContext={{ geofences }}
            >
            <FormButtons history={props.history} />
          </Form>
        </Grid>
      </Grid>
    </>
  ) : <p>Loading...</p>;
};

const uiSchema = {
  geofenceIds: {
    'ui:field': GeofenceSelect
  }
};

const schema = {
  properties: {
    name: { type: 'string', title: 'Name' },
    geofenceIds: {
      type: 'array',
      items: {
        type: 'string'
      },
      default: []
    },
    description: { type: 'string', title: 'Description' }

  },
  required: [ 'name' ]
};

const newGeofenceGroup = () => ({
  geofenceIds: [],
  viewSettings: {
    center: [
      59.3258,
      18.0724
    ],
    zoom: 13
  }
});

const EditActions = ({ basePath, resource }) => (
  <ra.TopToolbar>
    <ra.ListButton basePath={basePath} resource={resource} />
  </ra.TopToolbar>
);

export default EditGeofenceGroup;