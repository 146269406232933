import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const GeofenceSelect = ({ formData, onChange, formContext }) => {
	const handleCheck = (id, checked) => {
		const newFormData = checked ? [ ...formData, id ] : 
			formData.filter(item => item !== id);

		onChange(newFormData);
	};

	const isChecked = id => {
		return formData.includes(id);
	}

  return (
     <TableContainer className='geofence-list'>
      <Table size='small' aria-label='geofences'>
        <TableHead>
          <TableRow>
          	<TableCell>{' '}</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formContext.geofences.map(geofence => (
            <TableRow key={geofence.id}>
				      <TableCell component='td' scope='row'>
				     		<div style={{
				     			backgroundColor: geofence.colorHex,
                  padding: 0,
				     			borderRadius: 2,
				     			width: 20,
				     			height: 20
				     		}}
				     		>
				     		</div>
				      </TableCell>
              <TableCell component='td' scope='row'>
                {geofence.name}
              </TableCell>
            	<TableCell component='td' scope='row'>
            		<Checkbox
            			checked={isChecked(geofence.id)} 
            			onClick={ev => handleCheck(
                    geofence.id, 
                    ev.target.checked
                  )}
            		/>
            	</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GeofenceSelect;
