import React from 'react';
import Form from '@rjsf/material-ui';
import Grid from '@material-ui/core/Grid';
import * as ra from 'react-admin';

import Map from './Map';
import FormButtons from '../FormButtons';

const EditGeofence = props => {
  const { id } = props;

  const dataProvider = ra.useDataProvider();
  const [ resource, setResource ] = React.useState();

  React.useEffect(() => {
    if (id) {
      dataProvider.sendRequest(`/geofences/${id}`).then(
        res => setResource(res.data)
      );

    } else {
      setResource(newGeofence()); 
    }
  }, [ dataProvider, id ]);

  const handleSubmit = async () => {
    const url = resource.id ? `/geofences/${id}` : '/geofences';
    const method = resource.id ? 'put' : 'post';
    const body = JSON.stringify(resource);

    await dataProvider.sendRequest(url, { method, body });
    props.history.push('/geofences');
  };

	return resource ? (
		<>
	    <EditActions {...props}/>
	    <Grid container spacing={2}>
	      <Grid item xs={7}>
	      	<Map
            zoom={resource.viewSettings.zoom}
            center={resource.viewSettings.center}
            setZoomPan={viewSettings => setResource(resource => ({
              ...resource,
              viewSettings
            }))}
            geoJson={resource.geoJson}
            setGeoJson={geoJson => setResource({
              ...resource,
              geoJson
            })}
            colorHex={resource.colorHex}
          />
	      </Grid>
	      <Grid item xs={5}>
          <Form
            formData={resource}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            onChange={({ formData }) => setResource(formData)}
            >
            <FormButtons history={props.history} />
          </Form>
	      </Grid>
	    </Grid>
  	</>
  ) : <p>Loading...</p>;
};

const uiSchema = {
  /*
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3
    }
  },
  */
  colorHex: {
    'ui:widget': 'color'
  }
};

const schema = {
  properties: {
    name: { type: 'string', title: 'Name' },
    description: { type: 'string', title: 'Description' },
    colorHex: { type: 'string', title: 'Color' }
  },
  required: [ 'name' ]
};

const newGeofence = () => ({
  geoJson: [],
  colorHex: '#3388FF',
  viewSettings: {
    center: [
      59.3258,
      18.0724
    ],
    zoom: 13
  }
});

const EditActions = ({ basePath, resource }) => (
  <ra.TopToolbar>
    <ra.ListButton basePath={basePath} resource={resource} />
  </ra.TopToolbar>
);

export default EditGeofence;