import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer } from 'react-leaflet';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { getLayers } from '../map_utils';

const useStyles = makeStyles(theme => ({
  card: {
    height: 'calc(100vh - 165px)'
  }
}));

const Map = props => {
  const classes = useStyles(props);
  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    if (!map) return;

    getLayers(map).forEach(layer => layer.remove());
    props.layers.forEach(layer => layer.addTo(map));

  }, [ map, props.layers ]);

  const handleMapCreated = map => {
    setMap(map);

    const setZoomPan = () => {
      const { lat, lng } = map.getCenter();
      
      props.setZoomPan({
        center: [ lat, lng ],
        zoom: map.getZoom()
      });
    }

    map.on('zoomend', setZoomPan);
    map.on('moveend', setZoomPan);
  };

  return (
    <MapContainer
      whenCreated={handleMapCreated}
      center={props.center}
      zoom={props.zoom} 
      zoomControl={false}
      className={classes.card}
      >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};

export default Map;
