import React from "react";
import { LoloAdmin, LoloResource } from '@lolocompany/react-admin-lolo';
import { Route } from 'react-router-dom';

import { Dashboard  } from './dashboard';
import RadTest from './RadTest';
import ShowDevice from './ShowDevice';

import EditGeofence from './geofence/Edit';
import EditGeofenceGroup from './geofence-group/Edit';

import ListGateways from './gateway/ListGateways';
import ListApns from './apn/ListApns';

import Layout from './layout';
import schemaTransforms from './schema-transforms';
import { apiUrlMapping } from './config/env'
import './App.css';

const App = () => {
  
  const baseUrl = process.env.REACT_APP_API_URL || apiUrlMapping[window.location.hostname];

  if (!baseUrl) {
    alert('URL mapping for "' + window.location.hostname + '" not found');
    return null;
  }

  return (
    <LoloAdmin
      apiUrl={baseUrl}
      accountsUrl={baseUrl + '/accounts/all'}
      dashboard={Dashboard}
      title='Lolo PVPC'
      layout={Layout}
      customRoutes={[
        <Route exact path="/radtest" component={RadTest} />
      ]}
    >
      <LoloResource
        name='accounts'
        createSchemaTransform={schemaTransforms.account.create}
      />
      <LoloResource
        name='radius-clients'
      />
      <LoloResource
        name='csps'
      />
      <LoloResource
        name='apns'
        list={ListApns}
        listSchemaTransform={schemaTransforms.apn.list}
      />
      <LoloResource
        name='subnets'
        edit={null}
        create={null}
        listSchemaTransform={schemaTransforms.subnet.list}
      />
      <LoloResource
        name='apn-groups'
      />
      <LoloResource
        name='device-groups'
      />
      <LoloResource
        name='devices'
        show={ShowDevice}
        createSchemaTransform={schemaTransforms.device.create}
      />
      <LoloResource
        name='device-ids'
      />
      <LoloResource
        name='dns-records'
      />
      <LoloResource
        name='acls'
      />
      <LoloResource
        name='aggregations'
      />
      <LoloResource
        name='automations'
      />
      <LoloResource
        name='geofences'
        edit={EditGeofence}
        create={EditGeofence}
      />
      <LoloResource
        name='geofence-groups'
        edit={EditGeofenceGroup}
        create={EditGeofenceGroup}
      />
      <LoloResource
        name='sessions'
        edit={null}
        create={null}
      />
      <LoloResource
        name='gateways'
        list={ListGateways}
      />
      <LoloResource
        name='vpn-tunnels'
      />
      <LoloResource
        name='ike-policy-templates'
      />
      <LoloResource
        name='ipsec-policy-templates'
      />
      <LoloResource
        name='egress-gateways'
      />
    </LoloAdmin>
  )
}

export default App;