import React from 'react';
import * as ra from 'react-admin';
import { Grid, Card, makeStyles, Typography } from '@material-ui/core';
import VolumeIcon from '@material-ui/icons/DataUsageOutlined';
import BarChart from './common/BarChart';
import Map from './common/Map';
import CardWithIcon from './common/CardWithIcon';
import LinearProgressBar from './common/LinearProgressBar';
import { formatBytes, getTodayDate } from './dashboard/utils';
import TimeAgo from 'react-timeago'
import { useGlobalContext } from './hooks/globalContext';
import FlatCard from './common/FlatCard';

const useStyles = makeStyles((theme) => ({
    show: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: theme.spacing(1)
    },
    card: {
      padding: theme.spacing(2),
    }
}));

const DeviceDetailDashboard = ({ ...props }) => {
  const classes = useStyles();
  const {selectedTheme} = useGlobalContext();
  const {props: {themeType}} = selectedTheme
  const {record} = props

  const [stateData, setStateData] = React.useState({
    usage: {bytes: 0, bytesByDate: {} },
    buckets: {},
    lastAcctByApn: {},
    location: {}
  });

  const [aggsData, setAggsData] = React.useState({});
  const dataProvider = ra.useDataProvider();
  const refresh = ra.useRefresh();

  React.useEffect(() => {
    if (!record.id) return;
    dataProvider.sendRequest(`/devices/${record.id}/state`)
      .then(res => {
        setStateData(res.data);
        const ids = Object.keys(res.data.buckets);
        return dataProvider.getMany('aggregations', { ids })
      })
      .then(res => {
        setAggsData(res.data.reduce(
          (memo, agg) => ({ ...memo, [agg.id]: agg }),
          {}
        ))
      });
  }, [dataProvider, record.id, setStateData, setAggsData])

  const generateUsageChartData = () => {
    const { usage: { bytesByDate }} = stateData;

    const data = new Array(10)
      .fill(null)
      .map((_, i) => {
        const date = new Date(); date.setUTCHours(-i * 24);
        const key = date.toISOString().slice(0, 10);
        return {
          key,
          value: bytesByDate[key] || 0
        };
      });

    return data.sort((a, b) => new Date(a.key) - new Date(b.key));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card className={classes.card}>
              <Typography variant="h5" gutterBottom>
                {record.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Created at <ra.DateField record={record} source="createdAt" showTime />
              </Typography>
              <LastActive data={stateData.lastAcctByApn} />
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <Typography variant="h5">Identities</Typography>
              <ra.ReferenceManyField
                reference='device-ids'
                target='deviceId'
                addLabel={false}
                {...props}
                >
                <ra.Datagrid>
                  <ra.TextField label='Value' source='value' />
                  <ra.TextField label='Type' source='type' />
                  <ra.DateField source='createdAt' />
                </ra.Datagrid>
              </ra.ReferenceManyField>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <Typography variant="h5">Sessions</Typography>
              <ra.ReferenceManyField
                reference='sessions'
                target='deviceId'
                addLabel={false}
                {...props}
                >
                <ra.Datagrid>
                  <ra.ReferenceField label='APN' source='apnId' reference='apns'>
                    <ra.TextField source='name' />
                  </ra.ReferenceField>
                  <ra.TextField label='IP address' source='ip' />
                  <ra.ReferenceField label='ACL' source='aclId' reference='acls'>
                    <ra.TextField source='name' />
                  </ra.ReferenceField>
                  <ra.DateField source='updatedAt'/>
                </ra.Datagrid>
              </ra.ReferenceManyField>
            </Card>
          </Grid>
          { Object.keys(stateData.buckets).length ? (
            <Grid item>
              <Card className={classes.card}>
                <Typography variant="h5">Buckets</Typography>
                {
                  Object.entries(aggsData).map(([aggId, agg]) => {
                    const bucket = stateData.buckets[aggId];
                    const usedBytes = bucket ? bucket.bytes : 0;
                    const maxBytes = agg.threshold;

                    const onReset = async () => {
                      await dataProvider.sendRequest(
                        `/devices/${stateData.id}/buckets/${aggId}`, {
                        method: 'delete'
                      });

                      refresh();
                    };

                    return (
                      <React.Fragment key={aggId}>
                        <LinearProgressBar
                          variant="determinate"
                          value={(Math.min(usedBytes / maxBytes * 100, 100))}
                          title={agg.name}
                          subtitle={`${formatBytes(usedBytes)} of ${formatBytes(maxBytes)}`}
                          onReset={onReset}
                        />
                      </React.Fragment>
                    )
                  })
                }
              </Card>
            </Grid>
          ) : null }
          <Grid item>
            <Card className={classes.card}>
              <Typography variant="h5">Device State</Typography>
              <pre style={{ maxWidth: 450 }}>{JSON.stringify(stateData, null, 2)}</pre>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Grid container direction="column" spacing={2}>
          {stateData.location.pos ? (
            <Grid item>
              <Map location={stateData.location}/>
            </Grid>
          ) : null}
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {
                   themeType === 'eun' ? (
                     <FlatCard
                        subtitle='Usage Today'
                        title={formatBytes(stateData.usage.bytesByDate[getTodayDate()] || 0)} 
                     />
                   ) : (
                    <CardWithIcon
                      icon={VolumeIcon}
                      title='Usage Today'
                      subtitle={formatBytes(stateData.usage.bytesByDate[getTodayDate()] || 0)}
                    />
                   )
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                {
                   themeType === 'eun' ? (
                     <FlatCard
                        subtitle='Total Usage'
                        title={formatBytes(stateData.usage.bytes)}
                     />
                   ) : (
                    <CardWithIcon
                      icon={VolumeIcon}
                      title='Total Usage'
                      subtitle={formatBytes(stateData.usage.bytes)}
                    />
                   )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{marginTop: -20}}>
            <BarChart title="Usage Trend" data={generateUsageChartData()} color={selectedTheme.palette.primary.main} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ShowDevice = props => {
  const classes = useStyles();

  return (
    <ra.Show classes={{ card: classes.show }} {...props}>
      <ra.SimpleShowLayout>
        <DeviceDetailDashboard />
      </ra.SimpleShowLayout>
    </ra.Show>
  );
};

const LastActive = ({ data }) => {
  const res = Object.entries(data)
    .sort(([ k1, v1 ], [ k2, v2 ]) => k2 - k1)
    .shift();

  if (!res) return null;

  return (
    <Typography variant="subtitle1" gutterBottom>
      Last active <TimeAgo date={res[1]} live={false}/>
    </Typography>
  )
};

export default ShowDevice