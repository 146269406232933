import account from './account';
import device from './device';
import subnet from './subnet';
import apn from './apn';

export default {
	account,
	device,
	subnet,
	apn
}