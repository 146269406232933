import themes from '../layout/themes';

const apiUrlMapping = {
  "pvpc.io":"https://be.pvpc.io/uYsyopCuFi1xDWgtTLq44y",
  "dev.pvpc.io":"https://be.dev.pvpc.io/uYsyopCuFi1xDWgtTLq44y",
  "uros.lolo.company":"https://prod.uros.lolo.company/api",
  "pvpc.lolo.company":"https://dev.pvpc.lolo.company/dc7bLsruuZ95cAgiEzvRQc",
  "eun.pvpc.io": "https://be.pvpc.io/uYsyopCuFi1xDWgtTLq44y",
  "localhost":"http://localhost:4001"
}
//"localhost":"https://be.pvpc.io/uYsyopCuFi1xDWgtTLq44y"

const themeMapping = {
  "localhost": themes.eun,
  "eun.pvpc.io": themes.eun
}

export {
  apiUrlMapping,
  themeMapping
}
